import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import '../../styles.css';
import SEOComponent from '../components/fields/seoField';
import CommonPageRenderer from '../components/pageRendererHoc/CommonPageRenderer';

const Certification = () => {
	return (
		<div className='static-page certification'>
			<CommonPageRenderer>
				<SEOComponent title='RNG Certification - Snakes & Ladders Plus' description='Random Number Generator Certificate' />
				<div className='page-content mainContainer'>
					<h2 class='page-heading align-center'>Random Number Generator Certificate</h2>
					<StaticImage src='../images/certfication.jpeg' alt='certificate' />
				</div>
			</CommonPageRenderer>
		</div>
	);
};

export default Certification;
